.readmore {
  padding: 2em 2.5em 0em 2.5em;
  margin-top: 4%;
  text-align: center;
  color: var(--fontColorPrimary);
  .txt-line-one {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
  }
  .img-icon2 {
    width: 14%;
  }
  .lorem {
    font-weight: normal;
    font-size: 9px;
    line-height: 13px;

    text-align: center;

    color: var(--fontColorPrimary);
  }
}
