@import "../../../sass/responsive.scss";
.txt-smile {
  display: flex;
  color: white;
  // margin-top: 18% !important;

  justify-content: space-evenly;

  .icon_smile {
    text-align: center;
    width: 25%;
    @include respond(phone) {
      margin-top: 1em;
      margin-bottom: 8%;
    }
  }
  .txtf {
    margin-right: 10%;
    margin-top: 1.5em;
    font-size: 23px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.7;
  }
}
