@import "../../sass/responsive.scss";
.container_recolectDirection {
  .text_1 {
    font-size: 25px;
    font-weight: 700;
    margin: 4em 0em 1em 0em;
    @include respond(phone) {
      margin: 6em 0em 2em 0em;
      font-size: 20px;
    }
  }

  h6 {
    margin-top: 4%;
    @include respond(phone) {
      margin-top: 5%;
    }
  }
  input {
    border: none;
    background-color: transparent;
    border: 2px solid var(--fontColorPrimary);
    color: var(--fontColorPrimary);
    border-radius: 10px;
    margin-bottom: 10px;
    width: 20%;
    height: 35px;
    text-align: center;
    @include respond(phone) {
      width: 58%;
    }
  }

  .btn_continue {
    margin-top: 8%;
    border-radius: 10px;

    width: 13em;
    padding: 2px;
    font-size: 16px;

    cursor: pointer;

    background: linear-gradient(180deg, #0a6dc9ec, #021738c5);
    border: 1px solid #000000;
    color: white;

    @include respond(phone) {
      margin-top: 20%;
    }
    h1 {
      margin-top: 2px;
      font-weight: 700;
      font-size: 15px;
    }
  }
}
.map-style {
  position: absolute;
  opacity: 0.2;
  z-index: 15;
  top: 0;
  height: 100vh;
  width: 100%;
  pointer-events: none;
}
