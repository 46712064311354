.introcheck {
  padding: 2em 2.5em 0em 2.5em;
  margin-top: 4%;
  text-align: center;
  color: var(--fontColorPrimary);
  .txt-line-one {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
  }
  .img-icon2 {
    width: 14%;
  }
  .container-terms {
    padding: 2em;

    .txt-lines {
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
    }
    .txt-read-more {
      font-weight: bold;
      font-size: 11px;
      line-height: 12px;
      text-decoration: none;
      text-align: right;
      color: var(--fontColorPrimary);
    }
    .txt-lines-title {
      text-align: left;

      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 11px;
    }
  }
}
