@import "../sass/responsive.scss";

// --------style old texts video --------------
/* .txts {
  margin-top: 20% !important;
  text-align: center;
  transform: rotate(360deg);


  .txt {
    font-size: 28px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.7;
  }
  .img_1 {
    width: 20%;
    z-index: 2;
  }
  .img_2 {
    width: 20%;
    z-index: 2;
  }
}

.color2 {
  .txt {
    font-size: 28px;
    color: white;
    font-family: Arial;
    font-weight: 400;
    opacity: 0.7;
  }
  text-align: center;
  transform: rotate(360deg);
  margin-top: 20%;
  @include respond(phone) {
    text-align: center;
    transform: rotate(90deg);
    margin-top: 10.3em !important;
  }
}
.color3 {
  .txt {
    font-size: 28px;
    color: white;
    font-family: Arial;
    font-weight: 400;
    opacity: 0.7;
  }
  margin-top: 20%;
  text-align: center;
  transform: rotate(360deg);
  @include respond(phone) {
    text-align: center;
    transform: rotate(90deg);
    margin-top: 9em !important;
  }
}
 */
// .square {
//   width: 75vw;
//   height: 73%;
//   @include respond(tab-land) {
//   }
// }
.MyLoader_spinneroverlay {
  margin-top: 20%;
  padding-left: 7%;
  padding-right: 7%;
  @include respond(tab-land) {
    margin-top: 60%;
  }
}
.MyLoader_overlay {
  margin-top: 10%;
  background-image: url("https://mdn.mozillademos.org/files/6457/mdn_logo_only_color.png");
  @include respond(phone) {
    margin-top: 40%;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover {
  -moz-appearance: number-input;
}

._loading_overlay_spinner {
  .circle {
    stroke: black;
  }
  svg {
    stroke: var(--fontColorPrimary) !important;
    circle {
      stroke:var(--fontColorPrimary) !important;
    }
  }
  color: var(--fontColorPrimary);
  stroke: var(--fontColorPrimary);
}

._loading_overlay_wrapper {
  color: var(--fontColorPrimary);
  stroke: var(--fontColorPrimary);
}
._loading_overlay_overlay {
  margin-top: 60%;
  color: var(--fontColorPrimary);
  stroke: var(--fontColorPrimary);
}
._loading_overlay_content {
  color: var(--fontColorPrimary);
  stroke: var(--fontColorPrimary);
}

.p-hint {
  color: var(--fontColorHint);
  text-align: "center";
  font-size: "10px";
  font-weight: "500";
  margin-top: "-10px";
}

.splitTextCustom {
  transform: var(--invertPng);
  color: var(--invertedColor);
}