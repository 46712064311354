@import "../../sass/responsive.scss";
.intro_logo {
 
  text-align-last: center;
  width: 100%;
  height: 400px;
  @include respond(phone) {
    width: 100%;
    height: 400px;
  }
  img {
    margin-top: 20%;

    width: 30%;
    @include respond(phone) {
      margin-top: 30%;
      padding: 10vh;
      width: 100%;
    }
  }
}
