@import "../sass/responsive.scss";
.biometrics {
  margin-top: 2%;
  text-align: center;
  .icon_face {
    text-align: center;
    width: 10%;
    @include respond(phone) {
      width: 20%;
    }
  }
  .text1_face{
    padding: 2% 20% 0% 20%;
    font-size: 30px !important;
  }
}
