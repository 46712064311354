.voice-screen {
  text-align: center;
  padding: 2em;
  .p1 {
    font-size: 20px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.9;
  }

  .p2 {
    margin-top: 3em;
    font-size: 18px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.9;
  }
  .p3 {
    font-size: 24px;
    color: white;
    font-family: Arial;
    font-weight: 800;
    opacity: 0.9;
    color: #acff43;
  }
  .p4 {
    font-size: 10px;
    font-weight: 600;
    margin-top: 7em;
    color: white;
  }
}
