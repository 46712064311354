.recolect {
  text-align: center;
  color: white;
  .txt-title {
    margin-top: 2em;
    font-weight: 600;
    font-size: 24px;

    text-align: center;

    color: #ffffff;

    text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.5);
  }
  .txt-subtitle {
    font-weight: 600;
    font-size: 18px;

    text-align: center;

    color: #ffffff;
  }
}
