@import url("https://fonts.googleapis.com/css?family=ABeeZee&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
body {
  height: 100vh;
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  -web-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background:url("./static/b001.png"); */
  /* background-color: #440830; */
  background: url("./assets/back-img2.jpeg");
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.AppFinal {
  background: white;
}
/* --------------intento de bloquear la rotacion de pantalla---------------------------- */
/* @media (orientation: landscape) {
  body {
          
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }

} */
.App2 {
  /* ANTEIA BACKGROUND 2.0 FILTER */
  /* background: linear-gradient(360deg, #2778c48e, #0b66eeda); */
  /* background-image: url("./assets/back-img.jpeg"); */
  /* background-image: radial-gradient(#047bd6, #000e1a);  */
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  --fontColorPrimary: "#ffffff"
}
.App3 {
  /* ANTEIA BACKGROUND 2.0 FILTER */
  /* background: linear-gradient(180deg,#2778c442,  #0066fe75);  */
  background: url("./assets/back-img2.jpeg");
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
@keyframes chbg {
  0% {
    background: url("./static/b001.png");
    background-size: cover;
  }
  20% {
    background: url("./static/b002.png");
    background-size: cover;
  }
  40% {
    background: url("./static/b003.png");
    background-size: cover;
  }
  60% {
    background: url("./static/b004.png");
    background-size: cover;
  }
  80% {
    background: url("./static/b005.png");
    background-size: cover;
  }
  100% {
    background: url("./static/b006.png");
    background-size: cover;
  }
}
.ecu {
  background: url("./static/ECU/ecu01.png");
  animation: chbgfinal 0.0009s infinite alternate;
  background-size: cover;
}
@keyframes chbgfinal {
  0% {
    background: url("./static/ECU/ecu01.png");
    background-size: cover;
  }
  5% {
    background: url("./static/ECU/ecu02.png");
    background-size: cover;
  }
  10% {
    background: url("./static/ECU/ecu03.png");
    background-size: cover;
  }
  15% {
    background: url("./static/ECU/ecu04.png");
    background-size: cover;
  }
  20% {
    background: url("./static/ECU/ecu05.png");
    background-size: cover;
  }
  25% {
    background: url("./static/ECU/ecu06.png");
    background-size: cover;
  }
  30% {
    background: url("./static/ECU/ecu07.png");
    background-size: cover;
  }
  35% {
    background: url("./static/ECU/ecu08.png");
    background-size: cover;
  }
  40% {
    background: url("./static/ECU/ecu09.png");
    background-size: cover;
  }
  45% {
    background: url("./static/ECU/ecu10.png");
    background-size: cover;
  }
  50% {
    background: url("./static/ECU/ecu11.png");
    background-size: cover;
  }
  55% {
    background: url("./static/ECU/ecu12.png");
    background-size: cover;
  }
  60% {
    background: url("./static/ECU/ecu13.png");
    background-size: cover;
  }
  65% {
    background: url("./static/ECU/ecu14.png");
    background-size: cover;
  }
  70% {
    background: url("./static/ECU/ecu15.png");
    background-size: cover;
  }
  75% {
    background: url("./static/ECU/ecu01.png");
    background-size: cover;
  }
  80% {
    background: url("./static/ECU/ecu02.png");
    background-size: cover;
  }
  85% {
    background: url("./static/ECU/ecu03.png");
    background-size: cover;
  }
  90% {
    background: url("./static/ECU/ecu04.png");
    background-size: cover;
  }
  95% {
    background: url("./static/ECU/ecu05.png");
    background-size: cover;
  }
  100% {
    background: url("./static/ECU/ecu06.png");
    background-size: cover;
  }
}
