.custom-datepicker {
  text-align: center;
  background-color: transparent;
  height: 40px;
  width: 246px;
  font-size: 16px;
  font-weight: 600;
  color: var(--fontColorPrimary);
  border: 0.5px solid var(--inputBorderOverlap);
  border-radius: 10px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  cursor: text;
  padding: 0.75rem;
  text-transform: uppercase;
}
