.button {
  border-radius: 7px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(180deg, #0a6dc9ec, #021738c5);
  border: 1px solid #000000d3;
}
.button1 {
  margin-bottom: 2%;
  color: #c4c4c4;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #c4c4c4;
  background-color: transparent;
  border-radius: 7px;
}
.button2 {
  border: 1px solid #000000de;
  font-size: 18px;
  color: white;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.788) 0%,
    rgba(0, 0, 0, 0.637) 100%
  );
  mix-blend-mode: normal;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.678);
  border-radius: 8px;
}
.button3 {
  // width:60% !important;
  border: 2px solid #000000ea;
  font-size: 18px;
  color: white;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.315) 0%,
    rgba(0, 0, 0, 0.322) 100%
  );
  mix-blend-mode: normal;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.button4 {
  border: none;
  font-size: 18px;
  color: white;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  mix-blend-mode: normal;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.button5 {
  border: none;
  font-size: 18px;
  color: white;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  mix-blend-mode: normal;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
