.general-pay {
  margin-top: 1em;
  .huella-container {
    padding: 0.5em;
    color: white;
    width: 90vw;
    height: 167vw;
    /* height: 50vw; */
    margin: auto;
    border: 2px solid #ffffff4f;
    border-radius: 10px;
    position: relative;

    text-align: center;
    text-align: -webkit-center;
    .text-1 {
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      /* or 109% */

      text-align: center;

      color: #ffffff;
      margin-bottom: 0.5em;
    }
    .div-1 {
      width: 97%;
height: 7%;
      margin: auto;
      border: 1px solid #c4c4c44d;
      border-radius: 10px;
      position: relative;
      top: 1vw;
      p {
        font-weight: 800;
        font-size: 20px;
    
        /* or 109% */
        margin-top: 0.3em;
        text-align: center;

        color: #ffffff;
      }
    }
    .div-2 {
      margin-top: 1em;
      padding: 1em;
      width: 97%;

      height: 45%;

      border: 1px solid #c4c4c44d;
      border-radius: 10px;
      .icon-div {
        width: 60%;
        height: 9vh;
        top: 43.5%;
        border-radius: 50%;

        border: 0.4px dashed #ffffff;
        img {
          padding: 0.5em;
        }
      }
    }
    .div-3 {
      width: 97%;
      margin-top: 1em;
      // height: 97%;
      // margin: auto;
      border: 1px solid #c4c4c44d;
      border-radius: 10px;
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        /* or 120% */
        padding: 0.4em;
        text-align: center;
        margin-top: 2%;
        color: #ffffff;
      }
    }
  }
}