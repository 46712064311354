@import "../../sass/responsive.scss";
.recolect_verify_2 {

  color: var(--fontColorPrimary);
  img {
    margin-top: 3%;
    width: 10%;
    @include respond(phone) {
      // margin-top: 3em;
      width: 15%;
    }
  }
  .text_1 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 2em;
    text-shadow: 0px 2px 1px #000000;
    @include respond(phone) {
      margin-top: 2em;
    }
  }
  .text_2 {
    font-size: 20px;
    margin-top: 6%;
    @include respond(phone) {
      font-size: 18px;
      // padding: 0% 20% 5% 20%;
    }
  }

  .div_btns {
    justify-items: center;
    display: inline-grid;
    .timer-style{
      font-size:36px;
      font-weight: 800;
    }
    .timer-message{
      font-size: 18px;
      // color: rgba(255, 255, 255, 0.35);
      color: var(--softFontColor)
    }
    .otp-inputs-code {
      display: inline-grid;
     
      .inputs-code {
        width: 45px !important;
        height: 60px;
        margin: 4px;
        border: none;
        // background-color: #e0e0e030;
        color: var(--fontColorPrimary);
        background-color: transparent;
        border: 2px solid var(--fontColorPrimary);
        border-radius: 10px;
        margin-bottom: 20px;

        @include respond(phone) {
          margin: 4px;
          width: 39px !important;
          height: 46px !important;
        }
      }
    }

    .btn_number_modify {
      margin-top: 9%;
      border-radius: 6px;
      background: transparent;

      border: 1px solid #9b9a9ac4;
      color: #9b9a9ac4;
      width: 12em;
      padding: 2px;

      h1 {
        margin-top: 2px;
        font-weight: 700;
        font-size: 15px;
      }
    }
    .btn_number_confirm {
      margin-top: 8%;
    border-radius: 10px;

    width: 13em;
    padding: 2px;
    font-size: 16px;

    cursor: pointer;

    background: linear-gradient(180deg, #0a6dc9ec, #021738c5);
    border: 1px solid #000000;
    color: var(--fontColorPrimary);

      @include respond(phone) {
        margin-top: 13%;
      }
      h1 {
        margin-top: 2px;
        font-weight: 700;
        font-size: 19px;
      }
    }

    .text_3 {
      margin-top: 10%;
      color: rgba(228, 227, 227, 0.541);
      font-size: 13px;
      font-weight: 600;
      display: grid;
      @include respond(phone) {
        margin-top: 18%;
      }
    }
  }
}
