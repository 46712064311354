@import "../../sass/responsive.scss";
.video-style {
  .style-before-cedula {
    margin-top: 12vh;
    margin-right: 1em;
  }
  .style-scan-cedula {
    margin-top: 5vh;
    margin-right: 1em;
    .div-left-cedula {
      margin-bottom: 2em;

      .img-content {
        text-align-last: center;
        img {
          @media (orientation: portrait) {
            // margin-top: 1em !important;
            transform: rotate(90deg);
          }
        }
      }
    }
    .div-right-cedula {
      margin-top: 2em;

      .img-content {
        text-align-last: center;
        img {
          @media (orientation: portrait) {
            margin-top: 1em !important;
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
