@import "../../sass/responsive.scss";
.confirmEmail {
  .verify {
    h2 {
      padding: 1%;
      margin-left: 5%;
      margin-right: 5%;
      padding-bottom: 0%;
      text-align: center;
      margin-top: 10%;
      @include respond(tab-land) {
        padding: 1%;
        padding-bottom: 0%;
        text-align: center;
      }
    }
  }
}
