.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.width-screen {
  width: 100vw;
}

.height-screen {
  height: 100vh;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.font-open-sans {
  font-family: "Open Sans", sans-serif;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-500 {
  font-weight: 500;
}

.font-white {
  color: #fff;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}
