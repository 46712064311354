@import "../../sass/responsive.scss";
.introPage {
  // filter: linear-gradient(to bottom,  #0066FE, #4B9EEA);
  // background-size: 400% 400%;
  // contain: paint;

  .first_text {
    margin-top: 2%;
    @include respond(phone) {
      margin-top: 15%;
    }
    h1 {
      font-size: 56px !important;
      font-weight: bold;
      @include respond(phone) {
        font-size: 50px !important;
      }
    }
    .second-text {
      margin-top: 2em;
      h5 {
        font-weight: 600;
        font-size: 18px;
      }
    }
    .img-icon {
      margin-top: 2em;
      width: 40px;
      height: 40px;
    }
  }
}
