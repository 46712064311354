.LastVoice {
  text-align: center;
  margin-top:10em;
  .last-p1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 36px;

    color: #ffffff;

  }
  .last-p2 {
    margin-top:4em;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;

    color: #ffffff;
  }
  .last-p3 {
    margin-top:15em;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    /* or 100% */

    text-align: center;

    color: #ffffff;
  }
}
