@import "../../sass/responsive.scss";
.recolectFinal {
  text-align: -webkit-center;
  margin-top: 10%;
  @include respond(phone) {
    margin-top: 30%;
  }
  .text_1 {
    font-weight: 600;
    font-size: 25px;
    @include respond(phone) {
      font-size: 20px;
    }
  }
  .col_final {
    display: table-cell;
    text-align: -webkit-center;
    .input_div {
      padding: 0% 10% 0% 10%;
      display: inline-flex;
      align-items: center;
      flex-direction: row-reverse;
      input {
        margin-top: 10%;
        padding: 5px;

        border: none;
        background-color: transparent;
        border: 2px solid #e0e0e030;
        color: white;
        border-radius: 10px;
        margin-bottom: 10px;
        width: 16em;
        // height: 35px;
        text-align-last: left;
        @include respond(phone) {
          margin-top: 10%;
          width: 15em;
        }
      }
      ::placeholder {
        color: rgba(175, 174, 174, 0.452);
        opacity: 1;
      }

      .eyefill {
        padding: 4% 2% 2% 2%;
        position: absolute;
        @include respond(phone) {
          padding: 5% 2% 2% 2%;
        position: absolute;
        }
      }
    }

    .text_2 {
      padding: 4% 1% 0% 1%;
      color: rgba(228, 227, 227, 0.596);
    }
    .btn_confirm {
      margin-top: 8%;
      border-radius: 10px;
  
      width: 13em;
      padding: 2px;
      font-size: 16px;
  
      cursor: pointer;
  
      background: linear-gradient(180deg, #0a6dc9ec, #021738c5);
      border: 1px solid #000000;
      color: white;

      @include respond(phone) {
        width: 11em;
        margin-top: 13%;
      }
      h1 {
        margin-top: 2px;
        font-weight: 700;
        font-size: 20px;
        @include respond(phone) {
          font-size: 15px;
        }
      }
    }
  }

  /*   .title-pass {
    margin-top: 5px;

    @include respond(big-desktop) {
      margin-top: 5%;
    }
  }

  .input1-pass {
    margin-left: 2%;
    @include respond(tab-port) {
      margin-left: 10%;
    }
    input {
      width: 15%;
      @include respond(tab-port) {
        width: 40%;
      }
    }
  }
  .input2-pass {
    margin-left: 2%;
    @include respond(tab-port) {
      margin-left: 10%;
    }
    input {
      width: 15%;
      @include respond(tab-port) {
        width: 40%;
      }
    }
  } */
}
