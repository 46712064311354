@import "../../../sass/responsive.scss";


.txts-back-cedula {
  text-align: center;
  .txt {
    font-size: 18px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.7;
  }

  .txt_resalt {
    font-size: 26px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.9;
  }
}
.txts {
  text-align: center;

  .txt {
    font-size: 18px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.7;
  }

  .txt_resalt {
    font-size: 26px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.9;
  }
  .img_1 {
    width: 20%;
    z-index: 2;
  }
  .img_2 {
    width: 40%;
    z-index: 2;
  }
}
.color1-top {
  display: flex;
  .txt {
    font-size: 20px;
    color: white;
    font-family: Arial;
    font-weight: 400;
    opacity: 0.9;
  }
  text-align: center;
  // transform: rotate(360deg);

  @include respond(phone) {
    text-align: center;
    // transform: rotate(90deg);
    // margin-top: 10.3em !important;
  }
}

.color1 {
  display: flex;
  .txt {
    font-size: 20px;
    color: var(--fontColorPrimary);
    font-family: Arial;
    font-weight: 400;
    opacity: 0.9;
  }
  text-align: center;
  // transform: rotate(360deg);

  @include respond(phone) {
    text-align: center;
    // transform: rotate(90deg);
    // margin-top: 10.3em !important;
  }
}
.color2 {
  display: flex;
  .txt {
    font-size: 20px;
    color: var(--fontColorPrimary);
    font-family: Arial;
    font-weight: 400;
    opacity: 0.9;
  }
  text-align: center;
  // transform: rotate(360deg);
 
  @include respond(phone) {
    text-align: center;
    // transform: rotate(90deg);
    // margin-top: 10.3em !important;
  }
}
.color3 {
  display: flex;
  .txt {
    font-size: 20px;
    color: var(--fontColorPrimary);
    font-family: Arial;
    font-weight: 400;
    opacity: 0.9;
  }

  text-align: center;
  // transform: rotate(360deg);
  @include respond(phone) {
    text-align: center;
    // transform: rotate(90deg);
    // margin-top: 10em !important;
  }
}

.square {
  width: 75vw;
  height: 73%;
}


//landscape: estilos para rotacion de pantalla horizontal
@media (orientation: landscape) {
  .txts {
    margin-top: 1em !important;
    .img_2 {
      width: 30%;
      z-index: 2;
    }
  }
  .color1-top {
    margin-top: 12em !important;
    transform: rotate(-360deg);
  }
  .color1 {
    margin-top: 5em !important;
    transform: rotate(-360deg);
  }

  .color2 {
    margin-top: 5em !important;
    transform: rotate(-360deg);
  }
  .color3 {
    transform: rotate(-360deg);
    margin-top: 5em !important;
  }


}
//portrait: estilos para rotacion de pantalla vertical
@media (orientation: portrait) {
  .txts {
    margin-top: 12em !important;
    transform: rotate(90deg);
  }
  .txts-back-cedula {
    margin-top: 12em !important;
    transform: rotate(90deg);
  }
  .color1-top {
    margin-top: 12em !important;
    transform: rotate(90deg);
  }
  .color1 {
    margin-top: 10em !important;
    // margin-top: 1em !important;
    transform: rotate(90deg);
  }
  .color2 {
    margin-top: 10em !important;
    // margin-top: 1em !important;
    transform: rotate(90deg);
  }
  .color3 {
    margin-top: 10em !important;
    transform: rotate(90deg);
  }
  .txt-face {
    margin-top: 0em;
  }
}

