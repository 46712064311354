.style_0 {
  background: rgba(1, 15, 0, 0.322) !important;
  padding: 0 !important;
  // background: url('./../../assets/back-img.jpeg')
}
.style_1 {
  //    background:rgba(206, 16, 48, 0.507) !important;
  background-image: url("./../../../assets/back-img.jpeg") !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}
.style_2 {
  color: white;
  // background:rgba(206, 16, 48, 0) !important;
}
.style_3 {
  border: 1px solid black !important;
  // background-color:#35c509 !important;
  background: #0c2966 !important;
  // background: linear-gradient(180deg, rgba(30,36,136,1) 11%, rgba(7,9,40,1) 60%) !important;
}
.style_4 {
  border: 1px solid #a9a6a694 !important;
  background-color: #a9a6a694 !important;
}
.style_5 {
  color: white !important;
}

.swal-hint-container {
  padding: 0 !important;
}

.content_hint {
  padding: 0 !important;
  height: 100vh;
  width: 100vw;
  background-size: cover;
}

.html_hint {
  padding: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.popup_hint {
  padding: 0 !important;
}

.innerHtml {
  height: 100%;
  padding-top: 10%;
  padding-left: 8vw;
  padding-right: 8vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.first_row {
  justify-content: space-between;
  display: flex;
  .txt-col {
    padding: 0 !important;
  }
  .first_text {
    text-align: left;
    font-weight: 800 !important;
    font-family: "Open Sans";
    font-size: 26px;
    font-style: "bolder";
    line-height: 24px;
  }
}

.main_col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 !important;
}

.img_small {
  width: 30vw;
  height: 30vw;
  padding: 0;
}

.img_icon {
  position: absolute;
  width: 10vw;
  height: 10vw;
  top: 80%;
  left: 40%;
}

.col_img {
  padding: 0 !important;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.user-image-row {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-left: 15vw;
  margin-right: 15vw;
  height: 26vh;
  .user-image-div {
    transform: rotate(-90deg);
    border-radius: 10px;
    border: 4px solid #011525;
    width: 25vh;
    height: 90vw;
    position: relative;
    top: -8vh;
  }
  .user-image {
    text-align: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

.second_row {
  margin-top: 3vh;
  display: flex;
  justify-content: space-between;
}

.third_row {
  margin-top: 3vh;
}

.fourth-row {
  margin-top: 3vh;
}

.second-text {
  font-weight: 800;
  font-size: 18px;
  font-family: "Open Sans";
}

.third-text {
  font-size: 24px;
  font-weight: 800;
  font-family: "Open Sans";
}

.fourth-text {
  font-size: 18px;
  text-align: center;
  font-weight: 800;
  font-family: "Open Sans";
}

.row-button {
  margin-top: 5vh;
  align-items: center;
  margin-bottom: 5vh;
  .button-retry {
    color: white;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    mix-blend-mode: normal;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    padding: 5%;
    height: 62px;
    width: 60vw;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 18px;
    border-width: 0;
  }
}

.contenedorSeleccionado{
  display: flex;
  flex-direction: column;
  .messageError{
    display: flex;
    justify-content: center;
    .messageErrorText{
      font-weight: 800 !important;
      font-family: "Open Sans";
      font-size: 26px;
      font-style: "bolder";
      line-height: 24px;
      margin-bottom: 1rem;
    }
  }  
  .contenedorImagen{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50%;
  }
}